<template>
    <div>
        <DatenschutzEn v-if="$i18n.locale === 'en'" />
        <DatenschutzDe v-else />
    </div>
</template>

<script>
// import DatenschutzDe from './de/Datenschutz';
// import DatenschutzEn from './en/Datenschutz';
const DatenschutzDe = () => import('./de/Datenschutz')
const DatenschutzEn = () => import('./en/Datenschutz')

// import i18n from '../i18n';

export default {
    components: {
        DatenschutzDe,
        DatenschutzEn,
    },
};
</script>
